.toolPicker {
  display: flex;
  flex-direction: column;
  flex-grow: none;
  background: #333;
  image-rendering: pixelated;
}

.toolPicker > div {
  color: #fff;
  image-rendering: pixelated;
}

.active {
  background: #04e;
  color: #fff;
}

.iconButton {
  width: 32px;
  height: 32px;
  margin: 1px;
  background-size: cover;
  image-rendering: pixelated;
}
