html,
body,
#root,
.App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  background: #222;
  height: 100%;
  overflow: hidden;
}
