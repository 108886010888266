.toolBar {
  display: flex;
  direction: row;
  width: 100%;
  padding: 4px;
  background: #333;
}

.toolBarButton {
  color: #fff;
  padding: 4px 16px;
  border-radius: 8px;
}

.active {
  background: #04e;
  color: #fff;
}
